<template>
  <div class="view-project">
    <div class="view-header">
      <h2>Projet: <span>{{project.name}}</span></h2>
      <button class="primary"
        @click="start"
        >Lancer un préwarm</button>
    </div>
    <nav class="nav">
      <router-link to="view">Résumé</router-link>
      <!-- <router-link to="sessions">Sessions</router-link> -->
      <router-link to="edit">Configuration</router-link>
      <!-- <router-link to="edit">Programmation</router-link>
      <router-link to="edit">Statistiques</router-link> -->
      <!-- <router-link to="api">API</router-link> -->
    </nav>
    <router-view></router-view>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('projects', [
      'project',
    ]),
  },
  methods: {
    ...mapActions('projects', [
      'loadProjects',
      'useProject',
    ]),
    ...mapActions('sessions', [
      'startSession',
    ]),
    async onEnter({ id }) {
      await this.useProject(id);
    },
    async start() {
      const id = await this.startSession({ projectId: this.project.id, sandbox: false });
      this.$router.push({ name: 'Session', params: { id } });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.onEnter(to.params));
  },
  beforeRouteUpdate(to, from, next) {
    this.onEnter(to.params);
    next();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

h2 span {
  @include text-gradient(linear-gradient(135deg,#833ab4, #fd1d1d))
}

nav {
  a {
    display: inline-block;
    text-decoration: none;
    padding: .5em 1em;
    outline: none;
    color: #333;
    border-radius: 1rem;
    transition: all .2 ease;
  }
  .router-link-active {
    background: linear-gradient(135deg,#444, #222);
    color: #fff;
  }
  margin-bottom: 1rem;
}

.progress {
  display: flex;

  .step::before {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid #555;
    background: #eee;
  }
}
</style>
